.signup__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 100%;
}

.signup__header {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.signup__back {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
}

.signup__back > img {
    width: 20px;
    height: 20px;
}

.signup__logo {
    height: 56px;
}

.signup__logo img {
    width: 100%;
    height: 100%;
}

.signup__input-container-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.signup__label {
    align-self: flex-start;
    font-family: SFProText-Semibold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 0.94;
}

.signup__number-container {
    display: flex;
    margin-top: 9px;
    margin-bottom: 20px;
}

.signup__input-container {
    display: flex;
    height: 40px;
    margin-top: 61px;
}

.signup__input-container-column {
    display: flex;
    flex-direction: column;
    height: 96px;
    justify-content: space-between;
    margin-top: 61px;
}

.number-container__phone {
    margin-right: 9px;
}

.signup__input-container .select__control {
    height: 40px;
    width: 64px;
}

.signup__note {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    line-height: 1.17;
    text-align: left;
    opacity: 0.7;
    margin-top: 17px;
}

.signup__note a {
    color: black;
}

.signup-bts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}
.signup__button {
    border-radius: 54px;
    width: calc(100% - 12px); /* 100% - horizontal margins */
}

.signup__skip-btn {
    margin-top: 20px;
}

.signup__skip-btn--hidden {
    display: none;
}
