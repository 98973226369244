.inline-button {
    text-align: center;
    font-family: SFProText, sans-serif;
    font-size: 14px;
    color: #1c75ff;
    text-decoration: underline;
    line-height: 1.31;
    background-color: transparent;
    border: none;
    padding: 0;
}

button.inline-button:disabled {
    opacity: 0.5;
}
