.carousel .control-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    z-index: 0;
}

.carousel .control-dots .dot {
    box-shadow: none;
    background-color: #cecece;
    width: 7px;
    height: 7px;
    opacity: 1;

}
.carousel .control-dots .dot.selected {
    background-color: #343434;
}

