.welcome__container {
    margin: 10px 30px 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.welcome__header {
    width: 100%;
}

.welcome__logo {
    width: 19px;
    height: 26px;
}

.welcome__logo > img {
    width: 100%;
    height: 100%;
}

.welcome__image {
    margin: 12px 27px 0;
}

.welcome__image > img {
    width: 100%;
    height: 100%;
}

.welcome__title {
    font-family: SFProText-Semibold, sans-serif;
    font-size: 26px;
    margin-top: 31px;
    align-self: flex-start;
}
.welcome__text {
    font-family: SFProText, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 12px;
}

.welcome__list {
    font-family: SFProText-Medium, sans-serif;
    font-size: 16px;
    line-height: 21px;
    align-self: flex-start;
    counter-reset: counter;
    margin-top: 26px;
}

.welcome__list-item {
    counter-increment: counter;
    display: flex;
    margin-bottom: 8px;
    align-items: center;
}
.welcome__list-item::before {
    content: counter(counter);
    display: flex;
    width: 25px;
    height: 25px;
    margin-right: 11px;
    border: 1px solid;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
.welcome__button {
    font-family: SFProText, sans-serif;
    border-radius: 54px;
    font-size: 16px;
    margin-top: 46px;
    margin-bottom: 28px;
}

