.forgot_password__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 100%;
}

.forgot_password__header {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.forgot_password__back {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
}

.forgot_password__back > img {
    width: 20px;
    height: 20px;
}

.forgot_password__logo {
    height: 56px;
}

.forgot_password__logo img {
    width: 100%;
    height: 100%;
}

.forgot_password__main-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.forgot_password__label {
    align-self: flex-start;
    font-family: SFProText-Semibold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 0.94;
}

.forgot_password__login-container {
    display: flex;
    margin-top: 9px;
    margin-bottom: 20px;
}
.forgot_password__login {
    margin-right: 9px;
}
.forgot_password__input-container {
    display: flex;
    flex-direction: column;
    margin-top: 53px;
    width: 100%;
}

.forgot_password__next-btn {
    border-radius: 54px;
    width: calc(100% - 12px); /* 100% - horizontal margins */
    margin-bottom: 30px;
}

