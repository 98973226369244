.business-category__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 12px 17px;
    border: solid 1px #eeeeee;
    box-shadow: 0 2px 6px 0 rgba(201, 205, 214, 0.25);
    border-radius: 10px;
    align-items: center;
    width: 111px;
    height: 111px;
    box-sizing: border-box;
}

.business-category__container--checked {
    border: solid 1px #465282;
}

.business-category__image {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.business-category__image > img {
    max-height: 35px;
    max-width: 35px;
}

.business-category__text {
    display: flex;
    align-items: center;
    height: 30px;
    font-family: SFProText, sans-serif;
    font-size: 15px;
    color: #575757;
    text-align: center;
}

.business-category__text--checked {
    color: black;
}
