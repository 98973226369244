.photo-carousel {
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    overflow-x: hidden;
    z-index: 100;
}


.photo-carousel__image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    flex-shrink: 0;
    transition: all 0.3s ease;
}

.photo-carousel__image > img {
    max-width: 100%;
    max-height: 100%;
}

.photo-carousel__left, .photo-carousel__right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    cursor: pointer;
    user-select: none;
    opacity: 0.5;
    border: none;
}
.photo-carousel__left > img, .photo-carousel__right > img {
    width: 7px;
    height: 14px;
}

.photo-carousel__left {
    left: 19px;
}

.photo-carousel__left-btn {
    position: absolute;
    left: 0;
    width: 20%;
    height: 100%;
    background: transparent;
    border: none;
}

.photo-carousel__right {
    right: 19px;
}

.photo-carousel__right-btn {
    position: absolute;
    right: 0;
    width: 20%;
    height: 100%;
    background: transparent;
    border: none;
}

.photo-carousel__hidden {
    visibility: hidden;
}
