.business-creation__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 100px;
    box-sizing: border-box;
    min-height: 100%;
    margin-bottom: 80px;
}

.navigation {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 100;
    box-shadow: 0 0 6px 0 #c9cdd6;
}

.navigation__content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: white;
}

.navigation__button {
    padding: 13px 27px;
    width: auto;
    height: auto;
    border-radius: 15px;
}

.navigation__button--back {
    background-color: white;
    color: #465282;
    border: 1px solid #465282;
    box-sizing: border-box;
}

.navigation__steps {
    font-family: SFProText-Semibold, sans-serif;
    font-size: 14px;
}


.business-creation__step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.business-creation__title {
    font-family: SFProText-Semibold, sans-serif;
    font-size: 30px;
    text-align: center;
}

.business-creation__subtitle {
    font-family: SFProText-Semibold, sans-serif;
    font-size: 16px;
    margin-top: 18px;
    align-self: flex-start;
    letter-spacing: -0.32px;
}

.business-type {
    margin-top: 24px;
}

.business-types-list {
    margin-top: 2px;
    display: flex;
    flex-direction: column;
}

.business-category {
    margin-top: 10px;
    margin-right: 10px;
}
.business-category:nth-child(3n) {
    margin-right: 0;
}

.business-categories-list {
    display: flex;
    flex-wrap: wrap;
    width: 353px;
}

.verification__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.verification__description {
    margin-top: 31px;
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    line-height: 1.29;
    font-weight: 500;
}

.verification__photo-picker {
    margin-top: 10px;
}

.juridical-name__description {
    margin-top: 40px;
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    line-height: 1.29;
    font-weight: 500;
    align-self: flex-start;
}
.juridical-name__input {
    margin-top: 10px;
}

.business-name__description {
    margin-top: 49px;
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    line-height: 1.29;
    font-weight: 500;
}

.business-name__input {
    margin-top: 10px;
}

.additional-info__container {
    padding: 0 10px;
}

.additional-info__title {
    font-family: SFProText-Semibold, sans-serif;
    font-size: 30px;
    text-align: center;
    line-height: 1.07;
}

.additional-info__description {
    margin-top: 10px;
    font-family: SFProText, sans-serif;
    font-size: 13px;
    color: #95989a;
    line-height: 1.15;
    text-align: center;
}

.nickname__description {
    margin-top: 44px;
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    line-height: 1.29;
    font-weight: 500;
}

.nickname__input {
    margin-top: 10px;
}

.address__description {
    margin-top: 35px;
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    line-height: 1.29;
    font-weight: 500;
}

.address__input {
    margin-top: 10px;
}

.business__photo-picker {
    margin-top: 35px;
}

.phone-number {
    margin-top: 61px;
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.phone__input {
    margin-top: 10px;
    font-family: SFProText, sans-serif;
    font-size: 16px;
}

@media screen and (max-width: 360px) {
    .business-categories-list {
        width: 232px;
    }

    .business-category:nth-child(3n) {
        margin-right: 10px;
    }

    .business-category:nth-child(2n) {
        margin-right: 0;
    }
}
