.select .select__control {
    border: none;
    border-bottom: 1px solid #c8c7cc;
    border-radius: 0;
    box-shadow: none;
    flex-wrap: nowrap;
}

.select .select__value-container {
    padding: 0;
}

.select__indicator-separator {
    display: none;
}

.select__dropdown-indicator svg {
    color: black;
}

.select__indicators {
    position: absolute;
    right: 0;
}