.slide__container {
    padding-bottom: 49px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slide__img {
    display: block;
    margin-top: 24px;
    padding: 0 45px;
    width: 100%;
}

.slide__img img {
    width: 100%;
    height: 100%;
}

.slide__description-title {
    margin-top: 24px;
    font-family: SFProText, sans-serif;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5;
    padding: 0 30px;
}

.slide__description {
    margin-top: 15px;
    font-family: SFProText, sans-serif;
    font-size: 14px;
    line-height: 1.31;
    padding: 0 30px;
}
