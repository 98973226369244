.onboarding__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 68px;
    min-height: 605px;
    box-sizing: border-box;
    height: 100%;
}

.onboarding__logo {
    height: 42px;
    margin-top: 42px;
}

.onboarding__title {
    margin-top: 12px;
    font-family: SFProText, sans-serif;
    font-size: 13px;
    line-height: 1.13;
    font-weight: normal;
    padding: 0 50px;
    text-align: center;
}
.onboarding__logo img {
    width: 100%;
    height: 100%;
}

.onboarding__sub-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

.onboarding__link {
    margin-top: 26px;
}

.onboarding__button {
    position: fixed;
    bottom: 0;
    margin: 0;
}
