.business-type__container {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 2px 6px 0 #c9cdd6;
    padding: 16px 17px 21px;
    border-radius: 15px;
    user-select: none;
}

.business-type__container--checked {
    border: solid 1px #465282;
    padding: 15px 26px 20px 16px;
}

.business-type__checkmark {
    width: 13px;
    height: 13px;
    display: none;
}

.business-type__checkmark--checked {
    display: block;
}


.business-type__checkbox {
    width: 23px;
    height: 23px;
    border: solid 1px #647189;
    border-radius: 50%;
    opacity: 0.35;
    display: flex;
    justify-content: center;
    align-items: center;
}

.business-type__checkbox--checked {
    background: #465282;
    opacity: 1;
}

.business-type__content {
    display: flex;
    flex-direction: column;
    flex-basis: 59%;
}

.business-type__name {
    margin-top: 11px;
    font-family: SFProText-Bold, sans-serif;
    font-size: 17px;
}

.business-type__description {
    margin-top: 11px;
    font-family: SFProText, sans-serif;
    font-size: 13px;
    font-weight: normal;
}

.business-type__image {
    flex-basis: 41%;
    align-self: center;
}

.business-type__image > img {
    width: 100%;
    height: 100%;
    max-height: 100px;
}
