.login__container {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-top: 58%;
    padding-bottom: 55px;
    justify-content: space-between;
}

.login__logo {
    height: 67px;
    /*margin-top: 217px;*/
}

.login__logo img {
    width: 100%;
    height: 100%;
}

.login__sub-container {
    display: flex;
    flex-direction: column;
}

.login__note {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    line-height: 1.17;
    text-align: center;
    margin: 0 25px;
}

.login__note a {
    color: black;
}

.login__button {
    margin: 24px 30px 0;
    border-radius: 54px;
    width: calc(100% - 60px); /* 100% - horizontal margins */
}

.login__link {
    margin-top: 19px;
}
