.button {
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    background-color: #465282;
    color: white;
    padding: 17px;
    width: 100%;
    border: none;
}

button.button[disabled] {
    opacity: 0.5;
}
