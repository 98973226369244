.textarea__container {
    position: relative;
    width: 100%;
}

.textarea {
    box-sizing: border-box;
    min-height: 40px;
    font-family: SFProText, sans-serif;
    font-size: 15px;
    line-height: 1.2;
    padding: 10px 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #c8c7cc;
    outline: none;
    border-radius: 0;
    resize: none;
}
