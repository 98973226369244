.signin__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 100%;
}

.signin__header {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.signin__back {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
}

.signin__back > img {
    width: 20px;
    height: 20px;
}

.signin__logo {
    height: 56px;
}

.signin__logo img {
    width: 100%;
    height: 100%;
}

.signin__main-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.signin__label {
    align-self: flex-start;
    font-family: SFProText-Semibold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 0.94;
}

.signin__input-container {
    display: flex;
    flex-direction: column;
    margin-top: 53px;
    width: 100%;
}

.signin__input-container input:last-child {
    margin-top: 16px;
}

.signin-bts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.signin__button {
    border-radius: 54px;
    width: calc(100% - 12px); /* 100% - horizontal margins */
}

.signin__forgot-pass-btn {
    margin-top: 20px;
}
