.photo-picker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 165px;
    background-color: #f5f5f5;
}

.photo-picker--selected {
    display: block;
    justify-content: initial;
    align-items: initial;
}

.photo-picker__image {
    width: 35px;
    height: 35px;
}

.photo-picker__text {
    margin-top: 15px;
    font-family: SFProText, sans-serif;
    font-size: 17px;
    line-height: 1.18;
}

.photo-picker__text--error {
    color: red;
}

.photo-picker__chosen-image {
    max-height: 60%;
}

.photo-picker__restrictions {
    margin-top: 8px;
    align-self: flex-start;
    font-family: SFProText, sans-serif;
    font-size: 13px;
    color: #989898;
    line-height: 1.38;
}

.files-count-container {
    display: flex;
    align-items: center;
    margin-top: 19px;
    width: 100%;
}

.files-count-container__paper-clip {
    width: 21px;
    height: 24px;
}

.files-count-container__text {
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    margin-left: 5px;
}
