.spinner__wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
}

.spinner__wrapper--splash {
    background-color: white;
}

.spinner__wrapper--hidden {
    display: none;
}
