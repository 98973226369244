.map-choose__container {
    cursor: pointer;
    margin-bottom: 22px;
    text-align: center;
    padding: 14px 25px;
    border-radius: 15px;
    background-color: #465282;
}

.map-choose__text {
    font-family: SFProText, sans-serif;
    font-size: 16px;
    line-height: 1.19;
    color: white;
}

.map__container {
    position: fixed !important;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 1000;
    top: 0;
    left: 0;
}

.search-bar__container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 34px);
    margin: 32px 17px 0;
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 15px;
}

.search-bar__text {
    font-family: SFProText-Medium, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.38;
    color: #6e6e6e;
}

.search-bar__input {
    margin-top: 7px;
    width: 100%;
}

.search-bar__clear {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 9px;
    right: 9px;
}
