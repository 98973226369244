.verification-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    height: 100vh;
}

.verification-container__title {
    font-family: SFProText-Semibold, sans-serif;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
}


.verification-container__text {
    text-align: center;
    font-family: SFProText-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    margin-top: 172px;
}

.verification-container__button {
    position: absolute;
    width: calc(100% - 40px);    /*full parent width minus horizontal paddings*/
    bottom: 10px;
    border-radius: 54px;
    margin-bottom: 20px;
}
